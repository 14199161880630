/** API Reference 
    Owl carousel -  http://www.owlcarousel.owlgraphic.com/docs/started-welcome.html
    Bootstrap - http://getbootstrap.com/javascript/
    Fancybox - https://fancyapps.com/fancybox/3/docs/
    SmartMenus - http://www.smartmenus.org/docs/

    */
    /** --------------- DO NOT EDIT BELOW THIS LINE --------------- */

    /** lazy load when scroll to image tag from screen */
document.addEventListener("DOMContentLoaded", function () {
    var lazyImages = [].slice.call(document.querySelectorAll("img.lazyImg"));
  
    if ("IntersectionObserver" in window) {
      var lazyImageObserver = new IntersectionObserver(
        function (entries, observer) {
          entries.forEach(function (entry) {
            // console.log("entry lazyImg", entry);
            if (entry.isIntersecting) {
              entry.target.classList.add("visible");
              var lazyImage = entry.target;
              lazyImage.src = lazyImage.dataset.imgsrc;
              lazyImage.classList.remove("lazyImg");
              lazyImageObserver.unobserve(lazyImage);
            }
          });
        },
        {
          rootMargin: "200px",
          threshold: 0.05,
        }
      );
  
      lazyImages.forEach(function (lazyImage) {
        lazyImageObserver.observe(lazyImage);
      });
    } else {
      // fallback
      $.each($("img.lazyImg"), function (n, i) {
        var t = $(this).data("imgsrc");
        $(this).attr("src", t);
      });
    }
  });
  
  //lazy load when use background images
  document.addEventListener("DOMContentLoaded", function () {
    var lazyBackgrounds = [].slice.call(
      document.querySelectorAll(".lazyBackground")
    );
  
    if ("IntersectionObserver" in window) {
      var lazyBackgroundObserver = new IntersectionObserver(
        function (entries, observer) {
          entries.forEach(function (entry) {
            // console.log("entry background", entry);
            if (entry.isIntersecting) {
              entry.target.classList.add("visible");
              var lazyImage = entry.target;
              var imgUrl = lazyImage.dataset.imgsrc;
              lazyImage.classList.remove("lazyBackground");
              lazyBackgroundObserver.unobserve(lazyImage);
              entry.target.style.backgroundImage = "url(" + imgUrl + ")";
            }
          });
        },
        {
          rootMargin: "200px",
          threshold: 0.05,
        }
      );
  
      lazyBackgrounds.forEach(function (lazyBackground) {
        lazyBackgroundObserver.observe(lazyBackground);
      });
    } else {
      // fallback
      $.each($(".lazy-background"), function (n, i) {
        var bgurl = $(this).data("imgsrc");
        $(this).css("background-image", "url(" + bgurl + ")");
      });
    }
  });

    /* deferred video/iframe/img until after the initial pageload  */
    function deferload() {
        $.each($("iframe , img , source"), function (n, i) {
            var t = $(this).data('src');
            $(this).attr("src", t);
        });
    }
    /* activate hamburger navigation */
    function hamburgerdrop(elemclick, target, animargs) {
        if (elemclick.length) {
            $(elemclick).click(function (e) {
                e.preventDefault();
                e.stopImmediatePropagation();

                /* toggle expanded target */
                $('.expand').map(function (index) {
                    if ($(this)[0] !== $(target)[0]) {
                        $(this).toggleClass('expand').stop(true, true).animate(animargs, 300);
                    }
                });
                /* toggle open elemclick */
                $('.open').map(function (index) {
                    if ($(this)[0] !== $(elemclick)[0]) {
                        $(this).toggleClass('open');
                    }
                });

                /* toggle hamburger */
                
                if ($(this).siblings('.menu-toggle').length) {
                    $('.menu-toggle').toggleClass('open');
                } else {
                    $(this).toggleClass('open');    
                }
                $(target).toggleClass('expand').stop(true, true).animate(animargs, 300);
            });
        }
        /* not target click handle */
        $(document).click(function (e) {
            if ($(target).is(":visible") && $(target).prevUntil('.menu-toggle').is(":visible") && !$(target).is(e.target)) {
                $(elemclick).toggleClass('open');
                $(target).toggleClass('expand').stop(true, true).animate(animargs, 300);
            }
        });
        /* stop propagation */
        $(target).click(function (e) {
            e.stopPropagation();
        });
    }

    /* owls on required devices only */
    function owlsonrequireddevicesonly(elem, breakpoint, args) {
        if ($(elem)[0]) {
            var s = $(elem);
            if (window.innerWidth < breakpoint) {
                s.owlCarousel(args)
            } else s.addClass("off");
            /* destroy owl carousel on resize */
            $(window).resize(function (e) {
                if (window.innerWidth < breakpoint) {
                    if ($(elem).hasClass("off")) {
                        s.owlCarousel(args);
                        s.removeClass("off")
                    }
                } else $(elem).hasClass("off") || (s.addClass("off").trigger("destroy.owl.carousel"), s.find(".owl-stage-outer").children(":eq(0)").unwrap())
            })
        }
    }

    /* synced owl */
    function syncedowl(bigimages, thumbs, bigimagesargs, thumbsargs, popuptype) {
        /* synced carousel variables */
        var sync1 = $(bigimages),
        sync2 = $(thumbs)
        flag = false,
        duration = 300;
        /* parsing args */
        if (sync1.length) {
            sync1.owlCarousel(bigimagesargs).on('changed.owl.carousel', function (e) {
                /* apply sync position on change */
                var realIndex = (e.property.value - Math.ceil(e.item.count / 2)) % e.item.count || 0;
                syncPosition(e);
                if (!flag) {
                    flag = true;
                    sync2.trigger('to.owl.carousel', [realIndex, duration, true]);
                    flag = false;
                }
                 
            });
        }


        /* parsing args */
        if (sync2.length) {
            sync2.owlCarousel(thumbsargs).on('click', '.owl-item', function () {
                sync1.trigger('to.owl.carousel', [$(this).index(), duration, true]);
                console.log($(this));
            })
            .on('changed.owl.carousel', function (e) {
                var realIndex = (e.property.value - Math.ceil(e.item.count / 2)) % e.item.count || 0;
                console.log(e.property.value);
                if (!flag) {
                    flag = true;
                    sync1.trigger('to.owl.carousel', [realIndex, duration, true]);
                    flag = false;
                }
            });
            /* add synced class on init */
            sync2.find(".owl-item").eq(0).addClass('synced');

        }
        /* sync position */
        function syncPosition(el) {
            var current = el.item.index;
            sync2
            .find(".owl-item").removeClass('synced').eq(current).addClass('synced');
        }
    }

    /* --------------- DO NOT EDIT ABOVE THIS LINE --------------- */

    /* create your functions here */

    /* ------------------------- executes when complete page is fully loaded ----------------------------- */

    $(window).on('load', function (e) {
        /* datepicker */
        if ($('.response-field-datepicker input').length) {
            $('.response-field-datepicker input').datepicker();
        }

        /* smart menu */
        if ($('#main-menu').length) {
            $("#main-menu").smartmenus({
                subIndicatorsText: '<span class="down-arrow"></span>'
            }).bind("click.smapi", function (e, t) {
                var n = $(this).data("smartmenus");
                if (n.isCollapsible()) {
                    var i = $(t);
                    o = i.dataSM("sub");
                    if (o && !o.is(":visible")) return n.itemActivate(i, !0), !1
                }
        });
        }
        if ($('#single-menu').length) {
            $("#single-menu").smartmenus({
                subIndicatorsText: '<span class="down-arrow"></span>'
            }).bind("click.smapi", function (e, t) {
                var n = $(this).data("smartmenus");
                if (n.isCollapsible()) {
                    var i = $(t);
                    o = i.dataSM("sub");
                    if (o && !o.is(":visible")) return n.itemActivate(i, !0), !1
                }
        });
        }
        /* hamburger menu toggle */
        if ($('.menu-toggle').length) {
            var animator = $('.menu-toggle');
            $('.menu-toggle, .leftnav-title').click(function (e) {
                e.stopPropagation();
                animator.toggleClass('open');
            });
        }

        /* create var */
        var html = "";
        var video = "";
        var vids = [];
        var player = [];
        var description = "";
        var linkurl = "";
        var target = "";
        /* ---------- array check & create html slides - responsive ---------- */
        if (typeof imageArray != 'undefined') {
            for (var i = 1; i < imageArray.length; i++) {
                /* check for the video url and include it in the slider */
                if (imageArray[i]['videourl'] != '') {
                    /* loading video above 1200px */
                    if (window.matchMedia("(min-width: 1199px)").matches) {
                        video = '<video data-id="' + i + '" id="videoid_' + i + '" class="video-js" controls muted crossorigin autoplay>' +
                        '<source src="' + imageArray[i]['videourl'] + '" type="' + imageArray[i]['videotype'] + '">' +
                        '</video>';

                        /*collect video id's in to an array */
                        vids[i] = "videoid_" + i;
                    }

                }
                /* check for the link  */
                if (imageArray[i]['linkurl'] != '') {
                    linkurl = '<a href="' + imageArray[i]['linkurl'] + '" class="linkurl clearfix" target="' + imageArray[i]['target'] + '">' + imageArray[i]['linktext'] + '</a>';
                }

                /* check for the title & description */
                if (imageArray[i]['displaytext'] != '') {
                    description = '<div class="description' + ' ' + imageArray[i]['displaylocation'] + '">' + imageArray[i]['displaytext'] + linkurl + '</div>';
                }

                html += '<div class="item">' + video +
                '<picture>' +
                '<source media="(max-width: 768px)" srcset="' + imageArray[i]['mobileimgurl'] + '">' +
                '<img class="img-responsive" src="' + imageArray[i]['desktopimgurl'] + '" alt="' + imageArray[i]['alt'] + '" title="' + imageArray[i]['title'] + '">' +
                '</picture>' + description +
                '</div>';

                /* unset var */
                video = '';
                description = '';
                linkurl = '';
                target = '';
            }
        }

        /* ---------- array check & create html slides for fullscreen ---------- */
        if (typeof imageArrayfullscreen != 'undefined') {
            for (var i = 1; i < imageArrayfullscreen.length; i++) {
                /* check for the video url and include it in the slider */
                if (imageArrayfullscreen[i]['videourl'] != '') {
                    /* loading video above 1200px */
                    if (window.matchMedia("(min-width: 1199px)").matches) {
                        video = '<video data-id="' + i + '" id="videoid_' + i + '" class="video-js" controls muted crossorigin autoplay>' +
                        '<source src="' + imageArrayfullscreen[i]['videourl'] + '" type="' + imageArrayfullscreen[i]['videotype'] + '">' +
                        '</video>';

                        /*collect video id's in to an array */
                        vids[i] = "videoid_" + i;
                    }
                }
                /* check for the link  */
                if (imageArrayfullscreen[i]['linkurl'] != '') {
                    linkurl = '<a href="' + imageArrayfullscreen[i]['linkurl'] + '" class="linkurl clearfix" target="' + imageArrayfullscreen[i]['target'] + '">' + imageArrayfullscreen[i]['linktext'] + '</a>';
                }

                /* check for the title & description */
                if (imageArrayfullscreen[i]['displaytext'] != '') {
                    description = '<div class="description' + ' ' + imageArrayfullscreen[i]['displaylocation'] + '">' + imageArrayfullscreen[i]['displaytext'] + linkurl + '</div>';
                }
                html += '<div class="item" style="background-image: url(' + imageArrayfullscreen[i]['desktopimgurl'] + ')">' + video +
                '<img class="img-responsive" src="' + imageArrayfullscreen[i]['mobileimgurl'] + '" alt="' + imageArrayfullscreen[i]['alt'] + '" title="' + imageArrayfullscreen[i]['title'] + '">' + description +
                '</div>';

                /* unset var */
                video = '';
                description = '';
                linkurl = '';
                target = '';
            }
        }

        /* carousel args */

        args = {
            items: 1,
            autoplay: !0,
            loop: !0,
            dots: !1,
            animateOut: "fadeOut",
            onChanged: function (event) {
                /* init videojs */
                if (vids.length) {
                    $.each(vids, function (key, value) {
                        if (value) {
                            player[key] = videojs(value);
                        }

                    });
                }

                /* loading different resources for mobile and desktop users */
                if (window.matchMedia("(max-width: 1200px)").matches) {
                    /* stop all playing videos*/
                    $("#eme-slider video").each(function () {
                        $(this).get(0).pause();
                    });
                    /* trigger autoplay */
                    $(event.target).trigger('play.owl.autoplay');

                } else {

                    var Obj = $(event.target).find(".owl-item").eq(event.item.index).has('video');
                    /*check for the video*/
                    if (Obj.length) {
                        /*stop owl if video */
                        $(event.target).trigger('stop.owl.autoplay');

                        /* stop all playing videos*/
                        $("#eme-slider video").each(function () {
                            $(this).get(0).pause();
                        });

                        /*play video*/
                        var objectid = Obj.find('video').data('id');
                        player[objectid].play().on('ended', function () {
                            /* trigger autoplay */
                            $(event.target).trigger('play.owl.autoplay');
                        });
                    } else {
                        /* trigger autoplay */
                        $(event.target).trigger('play.owl.autoplay');
                    }
                    Obj = '';
                }
            }
        }
        /* init slider */
        if ($('#eme-slider').length) {
            /* apply carousel only if slider items > 1 */
            if (typeof imageArraycount != 'undefined') {
                if (imageArraycount != 1) {
                    $('#eme-slider').append(html).owlCarousel(args);
                }
            }
        }

        /**----------------------------------------------- Multiple Videos with Image slider ----------------------------------------------
         * Video first slide with slider
         * 2018 december development
         */
        
        var videoPlayer = [];
        var videoList = [];
        var playerInitialized = false;
        var optionsPlayer = {
            autoplay : false,
        }

        //checking video or image and do the process
        function sliderRun(event){

            /*stop owl autoplay  */
            $(event.target).trigger('stop.owl.autoplay');

            //pause all video players
            for(var i = 0; i < videoPlayer.length; i++){
                if(videoPlayer[i] != undefined){
                    videoPlayer[i].pause();// pause the player
                    videoPlayer[i].currentTime(0);// set first frame of the video
                }
            }

            //check disable video js on mobiles
            if (window.matchMedia("(max-width: 1200px)").matches) {
                /* trigger autoplay */
                $(event.target).trigger('play.owl.autoplay',[5000]);

            }else{
                var Obj = $(event.target).find(".owl-item").eq(event.item.index).has('video');

                /*check for the video*/
                if (Obj.length) {
        
                    var objectid = Obj.find('video').attr('data-id');
    
                    //check video download time
                    function checkDownloadTime(){
                        var howMuchIsDownloaded =  videoPlayer[objectid].bufferedPercent();
                        if(howMuchIsDownloaded > 0){
                            //video totally downloaded
                            stopchecking();
                        }
                    }
    
                    //stop video download time checking
                    function stopchecking(){
                        clearInterval(downloadTimeCheck);
                        playVideo();
                    }
    
                    //play current video
                    function playVideo(){
                        videoPlayer[objectid].play();
                        videoPlayer[objectid].on('ended', function () {
                            /* trigger autoplay */
                            $(event.target).trigger('play.owl.autoplay',[300]);
                        });
                    }
    
                    var downloadTimeCheck = setInterval(checkDownloadTime, 300);

                } else {

                    /* trigger autoplay */
                    $(event.target).trigger('play.owl.autoplay',[5000]);
        
                }

                Obj = '';
            }
        }

        //owl carousel on init
        function onInitialized(event){
            var owlItems = $(event.target).find(".owl-item");

            //check video items and assign IDs
            owlItems.each(function(idx, item) {
                if($(item).find('video').length){
                    $(item).find('video').attr('id', 'video_'+idx);
                    $(item).find('video').attr('data-id', idx);
                    var videoId = 'video_'+idx;
                    videoList[idx] = videoId;
                }
            });

            if (videoList.length) {
                //initialize video players
                $.each(videoList, function (key, value) {
                    if (value) {
                        videoPlayer[key] = videojs(value, optionsPlayer, ready);
                    }
                });
            } else {
                //if not available first slide video
                sliderRun(event);
            }

            //video player ready state
            function ready(){
                if(playerInitialized == false){
                    playerInitialized = true; // set player initialized
                    sliderRun(event);
                }
            }
        }

        //owl carousel on changed
        function onChanged(event){
            if(playerInitialized){
                sliderRun(event);
            }
        }

        // single video or image available state
        function onlySingleVideoAvailable(){
            if (window.matchMedia("(min-width: 1200px)").matches) {
                if($('#eme-slider-v2').find('video').length){
                    var optionsSinglePlayer = {
                        autoplay : false,
                        loop : true,
                    }
                    var singleVideoPlayer = '';
                    var obj = $('#eme-slider-v2').find('video').attr('id','single_video_player');
                    
                    singleVideoPlayer = videojs('single_video_player', optionsSinglePlayer, singlePlayerReady);
                    
                    function singlePlayerReady(){
                        singleVideoPlayer.play();
                    }
                }
            }
        }

        //single video or image available with init owl carousel
        function onlySingleVideoAvailableWithOwlCarousel(argsSlider){
            argsSlider.loop = false;
            argsSlider.mouseDrag = false;
            argsSlider.touchDrag = false;
            argsSlider.pullDrag = false;
            argsSlider.freeDrag = false;

            //video loop enable
            optionsPlayer.loop = true;
            $('#eme-slider-v2').append(htmlBlock).owlCarousel(argsSlider);
        }

        //init eme slider version 2 slider
        if($('#eme-slider-v2').length){
            var argsSlider = {
                items: 1,
                autoplay: false,
                loop: true,
                dots: true,
                nav: true,
                navText: ['<span><svg width="12" height="14" aria-hidden="true"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#left-arrow"></use></svg></span>', '<span><svg width="12" height="14" aria-hidden="true"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#right-arrow"></use></svg></span>'],  
                animateOut: "fadeOut",
                onInitialized: onInitialized,
                onChanged: onChanged, 
            }

            var htmlBlock = '';
            if (typeof videoFirstSliderArray != 'undefined') {
                htmlBlock = videoFirstSliderArray;
            }

            /* apply carousel only if slider items > 1 */
            if (typeof videoFirstSliderArraycount != 'undefined') {
                if (videoFirstSliderArraycount > 1) {
                    //more than 1 items available
                    $('#eme-slider-v2').append(htmlBlock).owlCarousel(argsSlider);
                }
                else{
                    // please use only one method at a time 

                    /** with owl carousel init method **/
                    //onlySingleVideoAvailableWithOwlCarousel(argsSlider);

                    /** without owl carousel init method **/
                    onlySingleVideoAvailable();

                }
            }
        }

        /* defer loading */
        deferload();
        allCarousels();
        wayPoints();
    });

/* ------------------------- executes when page scroll ----------------------------- */

$(window).scroll(function (e) {

});

/* ------------------------- executes when page resized ----------------------------- */

$(window).resize(function (e) {});

/* ------------------- executes when the html document is loaded and the dom is ready ---------------- */

$(document).ready(function (e) {

    //videoFirstslider();

    /* animation args */
    animargs = {
        top: "toggle",
        opacity: "toggle"
    }
    /* main navigation */
    hamburgerdrop('.mainmenutoggle', '.main-menu-container', animargs);
    /* left navigation */
    hamburgerdrop('.shareparent .menu-toggle , .leftnav-title', '#left-navbar', animargs);

    /* fancybox 3 */
    if ($('[data-fancybox]').length) {
        $('[data-fancybox]').fancybox();
    }

    /* owls on required devices only */
    /*(landscape phones, less than 768px)*/
    var breakpoint = 767;
    e = {
        loop: !1,
        margin: 15,
        responsive: {
            0: {
                items: 1
            }
        }
    }
    /** @param
    0 - elem id / elem class
    1 - breakpoint where you want carousel
    2 - carousel args
    */
    owlsonrequireddevicesonly("#carousel-on-devices", breakpoint, e);

    /* if synced carousel available only */
    b = {
        items: 1,
        dots: 1,
        loop: 1
    }

    t = {
        items: 2,
        margin: 1,
        loop: 1,
        responsive: {
            0: {
                dots: 1
            },
            768: {
                items: 4
            }
        }
    }

    /** @param
        0 - elem id / elem class - big images
        1 - elem id / elem class - thumb images
        2 - big images carousel args
        3 - thumbs carousel args
        4 - magnificpopup type ex: image/iframe
        */

        syncedowl("#big-images", "#thumbs", b, t, "image");

        // Nice select initiator
        // $(document).ready(function() {
        //     $('select').niceSelect();
    
        //     //var currentVal  = $('.nice-select .current').val();
        //     $(document).on('click','.nice-select .list .option',function(){
        //         //var currentVal  = $('.nice-select .current').val();
        //         $(this).parents('.response-field-dropdown').find('label').hide();
        //     });
            
        // });

        languageDropDown();
    });

function videoFirstslider() {
    var owl = $('#eme-video-slider');
    owl.owlCarousel({
        loop:true,
        margin:10,
        nav:false,
        dots:false,
        items:1
    });

    owl.on('translate.owl.carousel',function(e){
        $('.owl-item video').each(function(){
            $(this).get(0).pause();
        });
    });

    owl.on('translated.owl.carousel',function(e){
        $('.owl-item.active video').get(0).play();
    })
    if(!isMobile()){
        $('.owl-item .item').each(function(){
            var attr = $(this).attr('data-videosrc');
            if (typeof attr !== typeof undefined && attr !== false) {
                var videosrc = $(this).attr('data-videosrc');
                $(this).prepend('<video muted><source src="'+videosrc+'" type="video/mp4"></video>');
            }
        });
        $('.owl-item.active video').attr('autoplay',true).attr('loop',true);
    }


}

function isMobile(width) {
    if(width == undefined){
        width = 768;
    }
    if(window.innerWidth <= width) {
        return true;
    } else {
        return false;
    }
}


function languageDropDown() {
    $ = jQuery.noConflict();
  
    //The function that is listing the the mouse
    jQuery(".selector .selector__list li").mouseover(function () {
      jQuery(".selector .selector__list li").removeClass("selected");
      jQuery(this).addClass("selected");
    });
  
    // if the li item is clicked display the value inside the button
    jQuery(".selector__list li ").click(function () {
      //select the span where we want the place te value
      jQuery(this)
        .parent()
        .parent()
        .parent()
        .find(".valueOfButton")
  
        //get the html we want to place in the span and fill the div .valueOfButton
        .html(jQuery(this).find($('a')).html());
  
      //populate the hidden form input element
      jQuery(this)
        .parent()
        .parent()
        .parent()
        .find("input")
        .val(jQuery(this).data("key"));
    });
  
    var pathname = window.location.pathname;
    var language_links = $(".selector__list a").get();
    var selector_open = false;
  
    $(".selector__toggle").on("click", function (e) {
      e.preventDefault();
      if (selector_open == false) {
        selector_open = open;
        $(this).parent().removeClass("closed").addClass("open");
      } else {
        selector_open = false;
        $(this).parent().removeClass("open").addClass("closed");
      }
    });
  
    $(".selector__list a").on("click", function () {
      selector_open = false;
      $(this).parents(".selector").removeClass("open").addClass("closed");
    });
  }

  function allCarousels() {
    //   Home template news slider
    $('.home-news-carousel').owlCarousel({
        loop: false,
        margin: 20,
        nav:true,
        navText: ['<span><svg width="12" height="14" aria-hidden="true"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#left-arrow"></use></svg></span>', '<span><svg width="12" height="14" aria-hidden="true"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#right-arrow"></use></svg></span>'],
        dots: true,
        lazyLoad:true,
        responsive:{
            0:{
                items:1
            },
            768:{
                items:2
            },
            1200:{
                items:3,
                dots: false
            }
        }
    });

  //international eye bank template - one item slider
  if ($(".owl-one-item").length) {
    $(".owl-one-item").owlCarousel({
      items: 1,
      loop: !0,
      margin: 10,
      autoplay: true,
      dots: !0,
      nav: !0,
      navText: ['<span><svg width="12" height="14" aria-hidden="true"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#left-arrow"></use></svg></span>', '<span><svg width="12" height="14" aria-hidden="true"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#right-arrow"></use></svg></span>'],  
      autoplaySpeed: 1000,
      autoplayTimeout: 5000,
      autoplayHoverPause: true
    });
  }

  }
  
$(function () {
var body_selector = document.getElementsByTagName("BODY")[0];
window.addEventListener("scroll", function () {
  if (document.body.getBoundingClientRect().top > -350) {
    body_selector.classList.remove("fixed-header");
  } else {
    body_selector.classList.add("fixed-header");
  }
});

if (document.body.getBoundingClientRect().top > -350) {
  body_selector.classList.remove("fixed-header");
} else {
  body_selector.classList.add("fixed-header");
}
});

  // gallery page
  $(".gallery-nav li").each(function (i) {
    $(this).click(function () {
      $("#tabwrapper")
        .find(".tabgal:eq('" + i + "')")
        .show()
        .siblings()
        .hide();
      $("#left-navbar").hide();
      $("#gallery-toggle").removeClass("open");
      $(".gallery-nav li").removeClass("active");
      $(this).addClass("active");

      $("#addtitle").empty();
      $("#addtitle").append($(this).text());
      // console.log(gettext);
    });
  });


//   waypoints
function wayPoints() {

    var root = document.getElementsByTagName( 'html' )[0]; // '0' to assign the first (and only `HTML` tag)
root.setAttribute( 'class', 'js' );
$(".to_fade_up").waypoint(function(){
    $(this[0,'element']).addClass("fade_up");
  }, {
    triggerOnce: true,
    offset: '100%'
  });
}